import { render, staticRenderFns } from "./index.vue?vue&type=template&id=e6c1013a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=e6c1013a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6c1013a",
  null
  
)

export default component.exports
import {QInnerLoading,QSpinnerOval,QScrollArea,QCard,QCardSection,QAvatar,QIcon,QMenu,QList,QItem,QItemSection,QLinearProgress,QBanner,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QInnerLoading,QSpinnerOval,QScrollArea,QCard,QCardSection,QAvatar,QIcon,QMenu,QList,QItem,QItemSection,QLinearProgress,QBanner})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
