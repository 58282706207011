//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

export default {
  name: 'aiprojects',
  data() {
    return {
      showInnerLoading: false,
      live: 'green',
      test: 'yellow',
      projectedCreated:'',
      adminProjectLimit:'',
    };
  },
  computed: {
    ...mapGetters({
      aiAccounts: 'getAIAccounts',
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
    }),
  },
  methods: {
    ...mapActions({
      getActiveAIAccounts: 'getActiveAIAccounts',
      updateActiveAIAccount: 'updateActiveAIAccount',
    }),
    selectProject(account, project) {
      const that = this;
      eventBus.updateAIAccount(project);
      that.updateActiveAIAccount(project);
      this.$router.push(`/?account=${account.id}&project=${project.id}`).catch(() => {});
    },
    loadProjects(data) {
      const that = this;
      that.showInnerLoading = true;
      that.getActiveAIAccounts(that.currentUser).then((res) => {
        console.log('projectlimit',res[0].confJson.projectLimit);
        console.log('projectlimit',res[0].projectlength);
        that.adminProjectLimit = res[0].confJson.projectLimit;
        if(res[0].totalLength){
        that.projectedCreated = res[0].totalLength;
        }
        else{
          that.projectedCreated ='0';
        }
        console.log(that.projectedCreated);
        console.log(that.adminProjectLimit);
        //set length in case of admin
      }).catch((err) =>{
         this.$notify({
          group: 'notifications',
          type: 'error',
          text: err.errmsg,
        });
      }).finally(() =>{
        that.showInnerLoading = false;
      })
    },
    openCreateProjectDrawer(account) {
      const that = this;
      account.accountId = account.id;
      eventBus.updateAIAccount(account);
      that.updateActiveAIAccount(account);
      eventBus.triggerProjectDrawer({ status: true, actionType: 'new' });
    },
    openUpgradeWindow(){
    window.open("https://www.evodynamics.io/upgrade?utm_medium=&utm_source=console&utm_content=my-projects");
    },
    openEditProjectDrawer(account, projectId) {
      const that = this;
      account.accountId = account.id;
      eventBus.updateAIAccount(account);
      that.updateActiveAIAccount(account);
      this.$store.commit('SET_EDIT_SOURCE' , {accountId: account.id ,projectId })
      eventBus.triggerProjectDrawer({ status: true, actionType: 'edit', projectId });
    },
  },
  mounted() {
    const that = this;
    const data = {
      accountId: that.currentAIAccount.id,
    };
    this.loadProjects(data);
  },
};
